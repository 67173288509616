import { useKeenSlider } from "keen-slider/react";
import Image from "next/image";
import React from "react";
import { container_classname, sub_section_heading } from "./constants";
import { GBButtonWithIcon } from "./CTAButtons";
import ExternalLinkButton from "./ExternalLinkButton";
import { LuArrowUpRight } from "react-icons/lu";

interface PerformanceStatsProps {
    metrics: number;
    metricsSuffix: string;
    highlights: string;
}

interface UserReviewProps {
    feedback: string;
    name: string;
    occupation: string;
    performanceStats: PerformanceStatsProps[];
    ctaButtonText: string;
    ctaLink: string;
}

interface TestimonialSectionDataProps {
    clientName: string;
    userReview: UserReviewProps;
    imageUrl: string;
    logoUrl: string;
    mobileCompactLogo: string;
}

const defaultTestimonialSectionData: TestimonialSectionDataProps[] = [
    {
        clientName: "Pick you trial",
        userReview: {
            feedback:
                "With Gallabox's broadcast feature, we're now able to engage with our entire customer base effectively, sharing tailored travel deals and destination updates that actually reach our audience.",
            name: "Meena",
            occupation: "Senior CRM Associate, Pickyourtrail",

            performanceStats: [
                { metrics: 77, metricsSuffix: "%", highlights: "Increase in lead generation" },
                { metrics: 70, metricsSuffix: "%", highlights: "Business growth impact" },
                { metrics: 40, metricsSuffix: "%", highlights: "Higher team productivity" },
            ],

            ctaButtonText: "Read story",
            ctaLink: "/case-study/pickyourtrail",
        },
        imageUrl: "https://assets.gallabox.com/gb-home/new-landing/testimony/company-image/pick-your-trial.png",
        logoUrl: "https://assets.gallabox.com/gb-home/new-landing/testimony/client-logos/pick-your-trial.png",
        mobileCompactLogo: "https://assets.gallabox.com/gb-home/new-landing/testimony/mobile/pick-your-trial.png",
    },
    {
        clientName: "Qatar insurance company",
        userReview: {
            feedback:
                "Gallabox streamlined communication, improved customer service, and increased productivity by 30%. It integrates with CRM, tracks customer interactions, and is user-friendly.",
            name: "Hazim Nazir",
            occupation: "Quality and Audit Officer, QIC",

            performanceStats: [
                { metrics: 31, metricsSuffix: "%", highlights: "Increase in employee productivity" },
                { metrics: 102, metricsSuffix: "%", highlights: "Growth in lead generation" },
                { metrics: 2, metricsSuffix: "X", highlights: "Improvement in CSAT rates" },
            ],

            ctaButtonText: "Read story",
            ctaLink: "/case-study/qatar-insurance-company",
        },
        imageUrl: "https://assets.gallabox.com/gb-home/new-landing/testimony/company-image/qic.png",
        logoUrl: "https://assets.gallabox.com/gb-home/new-landing/testimony/client-logos/qic.png",
        mobileCompactLogo: "https://assets.gallabox.com/gb-home/new-landing/testimony/mobile/qic.png",
    },
    {
        clientName: "Dubai housekeeping",
        userReview: {
            feedback:
                "Client communication is not about stopping at a particular interaction. It has to be correlated with what the client has already said, has already been saying, and has said two months back. And Gallabox helped us with consolidating all these.",
            name: "Param Singh",
            occupation: "CEO, Dubai Housekeeping",

            performanceStats: [
                { metrics: 25, metricsSuffix: "%", highlights: "Increase in team productivity" },
                { metrics: 47, metricsSuffix: "%", highlights: "Higher CSAT ratings" },
                { metrics: 31, metricsSuffix: "%", highlights: "Business growth from WhatsApp" },
            ],

            ctaButtonText: "Read story",
            ctaLink: "/case-study/dubai-housekeeping",
        },
        imageUrl: "https://assets.gallabox.com/gb-home/new-landing/testimony/company-image/dubai-house-keeping-company.png",
        logoUrl: "https://assets.gallabox.com/gb-home/new-landing/testimony/client-logos/dubai-housekeeping.png",
        mobileCompactLogo: "https://assets.gallabox.com/gb-home/new-landing/testimony/mobile/dubai-house-keeping.png",
    },
    {
        clientName: "Footprint Real Estate",
        userReview: {
            feedback:
                "Gallabox provides innovative features that streamline our WhatsApp follow-ups, including sequencing, bots, and Click-to-WhatsApp (CTWA) capabilities—all of which have proven highly effective in our testing.",
            name: "Avinash Babu",
            occupation: "Assistant Digital Marketing Manager, Footprint Real Estate",

            performanceStats: [
                { metrics: 150, metricsSuffix: "%", highlights: "Growth in first-time customer conversion" },
                { metrics: 100, metricsSuffix: "%", highlights: "Increase in lead generation" },
                { metrics: 100, metricsSuffix: "%", highlights: "Expansion in customer base" },
            ],

            ctaButtonText: "Read story",
            ctaLink: "/case-study/footprint-real-estate",
        },
        imageUrl: "https://assets.gallabox.com/gb-home/new-landing/testimony/company-image/footprint-real-estate.png",
        logoUrl: "https://assets.gallabox.com/gb-home/new-landing/testimony/client-logos/footprint.svg",
        mobileCompactLogo: "https://assets.gallabox.com/gb-home/new-landing/testimony/client-logos/footprint.svg",
    },
];

interface TestimonialSectionProps {
    testimonialSectionData?: TestimonialSectionDataProps[];
}

const TestimonialSection: React.FC<TestimonialSectionProps> = (props) => {
    const { testimonialSectionData = defaultTestimonialSectionData } = props;
    const [opacities, setOpacities] = React.useState<number[]>([]);
    const [previousIndex, setPreviousIndex] = React.useState<number>(0);
    const [selectedIndex, setSelectedIndex] = React.useState<number>(0);
    const [randomNumber, setRandomNumber] = React.useState<number>(Math.random());

    const autoPlay = true;
    const autoPlayInterval = 7000;

    const [sliderRef, instanceRef] = useKeenSlider({
        initial: 0,
        slides: testimonialSectionData.length,
        loop: true,
        drag: false,
        detailsChanged(slider) {
            const newOpacities = slider.track.details.slides.map((slide) => slide.portion);
            newOpacities.forEach((opacity, idx) => {
                const elements = document.querySelectorAll(`[id="slider-id-${idx}"]`);
                elements.forEach((elm) => {
                    if (!elm) return;
                    (elm as HTMLElement).style.opacity = String(opacity);
                });
            });
            setOpacities(newOpacities);
        },
    });

    const currentSlideIndex = instanceRef.current?.track?.details?.rel;

    const [thumbnailRef] = useKeenSlider(
        {
            initial: 0,
            slides: {
                perView: 4,
                spacing: 10,
            },
        },
        [
            (slider) => {
                const addClickEvents = () => {
                    slider.slides.forEach((slide, idx) => {
                        slide.addEventListener("click", () => {
                            if (instanceRef.current) {
                                if (typeof instanceRef.current?.track?.details?.rel === "number") {
                                    setPreviousIndex(instanceRef.current?.track?.details?.rel);
                                }
                                instanceRef.current.moveToIdx(idx);
                                setSelectedIndex(idx);
                                setRandomNumber(Math.random());
                            }
                        });
                    });
                };

                slider.on("created", () => {
                    if (!instanceRef.current) return;
                    addClickEvents();
                    if (instanceRef.current) {
                        instanceRef.current.on("animationStarted", (main) => {
                            const nextIdx = main.animator.targetIdx || 0;
                            slider.moveToIdx(Math.min(slider.track.details?.maxIdx, nextIdx));
                            setRandomNumber(Math.random());
                        });
                    }
                });
            },
        ]
    );

    const desktopLogos = testimonialSectionData.map((testimony) => testimony.logoUrl);

    const mobileLogos = testimonialSectionData.map((testimony) => testimony.mobileCompactLogo);

    React.useEffect(() => {
        const interval = setInterval(() => {
            if (autoPlay && instanceRef.current) {
                instanceRef.current.next();
                setSelectedIndex((prev) => {
                    if (prev === testimonialSectionData.length - 1) return 0;
                    return prev + 1;
                });
            }
        }, autoPlayInterval);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [randomNumber]);

    return (
        <section className="lg:py-20 pt-16 pb-5">
            <div className={`${container_classname} flex flex-col lg:gap-14 gap-10`}>
                <div>
                    <h2 className={`${sub_section_heading} mb-5`} style={{ fontFamily: "Degular Display" }}>
                        See how we help our customers drive conversions
                    </h2>
                    <ExternalLinkButton href="/case-study" target="_blank" rel="noreferrer">
                        View all customer stories
                    </ExternalLinkButton>
                </div>
                <div>
                    <div className="lg:block hidden">
                        <div ref={sliderRef} className="fader">
                            <Testimony testimonialSectionData={testimonialSectionData} selectedIndex={selectedIndex} previousIndex={previousIndex} opacities={opacities} />
                        </div>
                    </div>
                    <div className="block lg:hidden">
                        <div ref={sliderRef} className="fader-mobile">
                            <Testimony testimonialSectionData={testimonialSectionData} selectedIndex={selectedIndex} previousIndex={previousIndex} opacities={opacities} />
                        </div>
                    </div>
                    <div className="lg:block hidden">
                        <div className="w-full keen-slider thumbnail-slider" ref={thumbnailRef}>
                            {desktopLogos.map((logo, idx) => {
                                const customStyle = currentSlideIndex === idx ? "loading" : "inactive";
                                return (
                                    <div key={idx} className="w-1/4">
                                        <div className={`box ${customStyle}`}>
                                            <div className={`box-border-top ${customStyle}`}></div>
                                        </div>
                                        <div
                                            className="w-full keen-slider__slide thumbnail-slider__slide flex items-center justify-center cursor-pointer"
                                            style={{ height: "80px", background: "#FFF" }}
                                        >
                                            <Image width={310} height={92} src={logo} alt={`${testimonialSectionData[idx].clientName} logo`} className="px-10 py-3 flex-shrink-0" />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <style jsx>
                            {`
                                .thumbnail-slider .thumbnail-slider__slide {
                                    max-width: unset !important;
                                    transform: none !important;
                                }
                            `}
                        </style>
                    </div>
                    <div className="w-full lg:hidden block">
                        <div className="w-full flex keen-slider thumbnail-slider" ref={thumbnailRef}>
                            {mobileLogos.map((logo, idx) => {
                                const customStyle = currentSlideIndex === idx ? "loading" : "inactive";
                                return (
                                    <div key={idx} className="w-1/4">
                                        <div className={`box ${customStyle}`}>
                                            <div className={`box-border-top ${customStyle}`}></div>
                                        </div>
                                        <div className="flex keen-slider__slide thumbnail-slider__slide h-11" style={{ background: "#F6F6F6" }}>
                                            <Image width={76} height={20} src={logo} alt="" className="p-2 flex-shrink-0" style={{ width: "100%", height: "100%" }} />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <style jsx>
                            {`
                                .thumbnail-slider .thumbnail-slider__slide {
                                    max-width: unset !important;
                                    transform: none !important;
                                }
                            `}
                        </style>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TestimonialSection;

interface TestimonyProps {
    testimonialSectionData: TestimonialSectionDataProps[];
    selectedIndex: number;
    previousIndex: number;
    opacities: number[];
}

const Testimony: React.FC<TestimonyProps> = (props) => {
    const { testimonialSectionData, selectedIndex, previousIndex, opacities } = props;
    const buttonText = testimonialSectionData[selectedIndex].userReview.ctaButtonText;
    return (
        <div>
            {testimonialSectionData.map((testimony, idx) => {
                const { clientName, userReview, imageUrl, logoUrl } = testimony;
                const { feedback, name, occupation, performanceStats } = userReview;
                const href = testimonialSectionData[selectedIndex].userReview.ctaLink;

                return (
                    <div
                        key={idx}
                        id={`slider-id-${idx}`}
                        className="fader__slide w-full lg:pb-12 pb-0"
                        style={{ opacity: selectedIndex === idx || previousIndex === idx ? opacities[idx] : 0 }}
                    >
                        <div className="lg:hidden block bg-white px-3 py-2 shadow-lg w-fit rounded-lg border border-solid border-gray-50">
                            <Image width={164} height={66} src={logoUrl} alt={`${clientName} logo`} />
                        </div>

                        <div className="lg:block hidden pb-4">
                            <Image width={31} height={22} src={"https://assets.gallabox.com/gb-home/new-landing/testimony/quote.png"} alt="quotation mark" />
                        </div>

                        <div className="w-full flex lg:gap-14 gap-0 lg:pt-0 pt-6">
                            <div className="w-full lg:flex-1 flex flex-col lg:gap-11 gap-8">
                                <div className="w-full flex flex-col gap-4">
                                    <p className="lg:text-xl lg:max-w-3xl text-base font-medium text-black">{feedback}</p>
                                    <div>
                                        <p className="text-base font-semibold text-gray-900">{name}</p>
                                        <p className="text-sm text-gray-700">{occupation}</p>
                                    </div>
                                </div>
                                <div className="w-full flex lg:gap-6 gap-4">
                                    {performanceStats.map((performance, index) => {
                                        const { metrics, metricsSuffix, highlights } = performance;
                                        return (
                                            <div key={index} className="lg:pl-3 pl-3 flex flex-col gap-2 h-auto lg:w-1/3 w-full" style={{ borderLeft: "3px solid #3364FA" }}>
                                                <p className="lg:text-6xl text-3xl font-bold" style={{ fontFamily: "Degular Variable" }}>
                                                    {metrics}
                                                    <span className="lg:text-6xl text-3xl font-bold" style={{ fontFamily: "Degular Variable" }}>
                                                        {metricsSuffix}
                                                    </span>
                                                </p>
                                                <p className="text-base lg:w-full w-20">{highlights}</p>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="lg:w-fit w-full">
                                    <GBButtonWithIcon variant="primary-dark" size="md" href={href} target="_blank" rightIcon={LuArrowUpRight}>
                                        {buttonText}
                                    </GBButtonWithIcon>
                                </div>
                            </div>
                            <div className="lg:flex hidden pt-1" style={{ width: "360px", height: "364px" }}>
                                <Image width={360} height={364} src={imageUrl} alt={`${clientName} image`} />
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
